import request from '@/utils/request.js'
// 查询订单列表
export function getOrderList(data) {
  return request({
    url: 'cereOrder/query',
    method: 'post',
    data
  })
}

// 查询订单详情
export function getOrderById(id) {
  return request({
    url: 'cereOrder/getById/'+ id,
    method: 'get'
  })
}

// 用户订单操作
export function orderOperation(data) {
  return request({
    url: 'cereOrder/orderOperation',
    method: 'put',
    data
  })
}

// 修改订单价格
export function modifyPrice(data) {
  return request({
    url: 'orderModify/modifyPrice',
    method: 'put',
    data
  })
}

// 订单修改记录查询
export function orderModifyUpdate(id) {
  return request({
    url: 'orderModify/getById/' + id,
    method: 'get',
  })
}

// 审核改价
export function checkPass(data) {
  return request({
    url: 'orderModify/checkPass',
    method: 'post',
    data
  })
}