<!-- 订单详情 -->
<template>
  <div class="container">
    <el-card>
      <h3>订单详情</h3>
      <div class="tile_box">
        <div class="title">订单信息</div>
      </div>
      <el-descriptions
        style="margin-bottom: 0px"
        v-show="dataJson.terminationReason"
      >
        <el-descriptions-item label="平台拒绝理由">
          <span style="font-size: 18px; font-weight: bold; color: red">
            {{ dataJson.terminationReason }}
          </span>
        </el-descriptions-item>
      </el-descriptions>
      <el-descriptions border>
        <el-descriptions-item label="订单编号">{{
          dataJson.orderCode
        }}</el-descriptions-item>
        <el-descriptions-item
          label="需求单号"
          v-if="userInfo.companyStatus == 2"
          >{{ dataJson.orderCode }}</el-descriptions-item
        >
        <el-descriptions-item label="订单状态">{{
          dataJson.orderStatus == 0
            ? "已接单"
            : dataJson.orderStatus == 1
            ? "执行中"
            : dataJson.orderStatus == 2
            ? "待验收"
            : dataJson.orderStatus == 3
            ? "已验收"
            : dataJson.orderStatus == 4
            ? "已支付"
            : dataJson.orderStatus == 5
            ? "已开票"
            : dataJson.orderStatus == 6
            ? "已完成"
            : "已取消"
        }}</el-descriptions-item>
      </el-descriptions>
      <el-descriptions border>
        <el-descriptions-item label="接单时间">{{
          dataJson.receiveTime
        }}</el-descriptions-item>
        <el-descriptions-item label="验收时间">{{
          dataJson.receiveTime
        }}</el-descriptions-item>
        <el-descriptions-item label="结束时间">{{
          dataJson.overTime
        }}</el-descriptions-item>
        <el-descriptions-item
          :label="
            userInfo.businessStatus == 2
              ? '发布企业'
              : userInfo.companyStatus == 2
              ? '接单车主'
              : ''
          "
          >{{
            userInfo.businessStatus == 2
              ? dataJson.requireIssuerName
              : userInfo.companyStatus == 2
              ? dataJson.orderDriverName
              : ""
          }}</el-descriptions-item
        >
        <el-descriptions-item label="支付方式">{{
          dataJson.payTypeCode == 0
            ? "完工付"
            : dataJson.payTypeCode == 1
            ? "现结"
            : dataJson.payTypeCode == 2
            ? "日结"
            : dataJson.payTypeCode == 3
            ? "月结"
            : dataJson.payTypeCode == null
            ? "无"
            : "季付"
        }}</el-descriptions-item>
        <el-descriptions-item label="项目类型">{{
          dataJson.projectType
        }}</el-descriptions-item>
      </el-descriptions>
      <el-descriptions border>
        <el-descriptions-item label="项目地址"
          >{{ dataJson.projectProvince }}{{ dataJson.projectCity
          }}{{ dataJson.projectCountry
          }}{{ dataJson.projectAddress }}</el-descriptions-item
        >
      </el-descriptions>
      <el-descriptions border>
        <el-descriptions-item label="施工备注">{{
          dataJson.orderRemark
        }}</el-descriptions-item>
      </el-descriptions>
      <el-descriptions style="margin: 20px 0">
        <el-descriptions-item label="订单合计">
          <span style="color: red; font-size: 18px; font-weight: bold">
            ￥{{ dataJson.amountTotal }}
          </span>
        </el-descriptions-item>
      </el-descriptions>
      <el-descriptions
        style="margin-bottom: 20px"
        v-show="dataJson.acceptReason"
      >
        <el-descriptions-item label="验收备注">
          <span style="font-size: 18px; font-weight: bold">
            {{ dataJson.acceptReason }}
          </span>
        </el-descriptions-item>
      </el-descriptions>
      <!-- 需求设备 -->
      <div>
        <div class="tile_box">
          <div class="title">需求设备</div>
        </div>
        <el-scrollbar style="margin-bottom: 40px">
          <div
            v-for="(v, i) in dataJson.cereOrderDetails"
            :key="i"
            class="items"
          >
            <el-descriptions border>
              <el-descriptions-item label="设备名称">{{
                v.vehicleName
              }}</el-descriptions-item>
              <el-descriptions-item label="设备类型"
                >{{ v.firstLevelType }}/{{
                  v.secondLevelType
                }}</el-descriptions-item
              >
              <el-descriptions-item label="设备规格">{{
                v.vehicleModel
              }}</el-descriptions-item>
            </el-descriptions>
            <el-descriptions border>
              <el-descriptions-item label="进场日期">{{
                v.slottingTime
              }}</el-descriptions-item>
              <el-descriptions-item label="租赁方式">{{
                v.rentType
              }}</el-descriptions-item>
              <el-descriptions-item label="工作量"
                >{{ v.leaseWork }}{{ v.leaseUnit }}</el-descriptions-item
              >
            </el-descriptions>
            <el-descriptions border>
              <el-descriptions-item label="机械数量">{{
                v.count
              }}</el-descriptions-item>
              <el-descriptions-item label="单价"><strong>{{
                v.unitPrice
              }}</strong>
              </el-descriptions-item>
              <el-descriptions-item label="合计"><strong style="color: red; margin-right: 5px">{{
                v.totalPrice
              }}</strong>元</el-descriptions-item>
            </el-descriptions>
          </div>
        </el-scrollbar>
      </div>
      <!-- 派送设备 -->
      <div>
        <div class="tile_box">
          <div class="title">派送设备</div>
        </div>
        <el-descriptions style="margin-top: 20px"> </el-descriptions>
        <el-scrollbar>
          <div v-for="(v, i) in dataJson.vehicles" :key="i" class="item">
            <el-image :src="v.vehicleImgs[0]"></el-image>
            <div class="flex">
              <el-descriptions border size="mini">
                <el-descriptions-item label="车辆名称">{{
                  v.vehicleName
                }}</el-descriptions-item>
                <el-descriptions-item label="类型"
                  >{{ v.firstLevelType }}/{{
                    v.secondLevelType
                  }}</el-descriptions-item
                >
              </el-descriptions>
              <el-descriptions border size="mini">
                <el-descriptions-item label="型号">{{
                  v.vehicleModel
                }}</el-descriptions-item>
                <el-descriptions-item label="地址"
                  >{{ v.vehicleProvince }}{{ v.vehicleCity
                  }}{{ v.vehicleCounty }}</el-descriptions-item
                >
              </el-descriptions>
              <el-descriptions border size="mini">
                <el-descriptions-item label="单价">{{
                  v.offer
                }}</el-descriptions-item>
                <el-descriptions-item label="品牌">{{
                  v.vehicleBrand
                }}</el-descriptions-item>
              </el-descriptions>
              <el-descriptions border size="mini">
                <el-descriptions-item label="进场时间">{{
                  v.slottingTime
                }}</el-descriptions-item>
                <el-descriptions-item label="工作量"
                  >{{ v.leaseWork }}{{ v.leaseUnit }}</el-descriptions-item
                >
              </el-descriptions>
              <el-button type="primary" size="mini" @click="checkDialog(v)"
                >查看明细</el-button
              >
            </div>
          </div>
        </el-scrollbar>
      </div>
      <!-- 改价设备 -->
      <div style="margin-top: 40px" v-if="afterModifyPrice">
        <div class="tile_box">
          <div class="title">改价设备</div>
        </div>
        <el-descriptions style="margin-top: 20px"> </el-descriptions>
        <el-scrollbar>
          <div v-for="(v, i) in vehicleModifyVos" :key="i" class="item">
            <el-image :src="v.vehicleImg"></el-image>
            <div class="flex">
              <el-descriptions border size="mini">
                <el-descriptions-item label="车辆名称">{{
                  v.vehicleName
                }}</el-descriptions-item>
                <el-descriptions-item label="类型"
                  >{{ v.firstLevelType }}/{{
                    v.secondLevelType
                  }}</el-descriptions-item
                >
              </el-descriptions>
              <el-descriptions border size="mini">
                <el-descriptions-item label="型号">{{
                  v.vehicleModel
                }}</el-descriptions-item>
                <el-descriptions-item label="地址"
                  >{{ v.vehicleProvince }}{{ v.vehicleCity
                  }}{{ v.vehicleCounty }}</el-descriptions-item
                >
              </el-descriptions>
              <el-descriptions border size="mini">
                <el-descriptions-item label="单价">{{
                  v.offer
                }}</el-descriptions-item>
                <el-descriptions-item label="品牌">{{
                  v.vehicleBrand
                }}</el-descriptions-item>
              </el-descriptions>
              <el-descriptions border size="mini">
                <el-descriptions-item label="进场时间">{{
                  v.slottingTime
                }}</el-descriptions-item>
                <el-descriptions-item label="工作量"
                  >{{ v.leaseWork }}{{ v.leaseUnit }}</el-descriptions-item
                >
              </el-descriptions>
              <el-button type="primary" size="mini" @click="checkDialog(v)"
                >查看明细</el-button
              >
            </div>
          </div>
        </el-scrollbar>
        <el-descriptions>
          <el-descriptions-item label="改价备注" style="margin-top: 20px">
            <span style="color: red; font-size: 18px; font-weight: bold">
              {{ afterModifyRemark }}
            </span>
          </el-descriptions-item>
        </el-descriptions>
        <el-descriptions style="margin: 0 0 20px 0">
          <el-descriptions-item label="改价后的价格">
            <span style="color: red; font-size: 18px; font-weight: bold">
              ￥{{ afterModifyPrice }}
            </span>
          </el-descriptions-item>
        </el-descriptions>
      </div>
      <div
        class="btn"
        style="text-align: right; margin-top: 10px"
        v-if="$route.query.val"
      >
        <el-button type="primary" size="small" @click="submit(true, '验收备注')"
          >通过</el-button
        >
        <el-button
          type="primary"
          size="small"
          @click="submit(false, '不合格备注')"
          >不合格</el-button
        >
      </div>
    </el-card>
    <!-- 机械信息 -->
    <el-dialog
      :visible.sync="dialogVisible"
      width="50%"
      :close-on-click-modal="false"
      class="pub_dialog"
    >
      <el-descriptions title="机械信息" border :column="2" size="small">
        <el-descriptions-item label="机械名称">{{
          dialogData.vehicleName
        }}</el-descriptions-item>
        <el-descriptions-item label="类型"
          >{{ dialogData.firstLevelType }}/{{
            dialogData.secondLevelType
          }}</el-descriptions-item
        >
        <el-descriptions-item label="型号">{{
          dialogData.vehicleModel
        }}</el-descriptions-item>
        <el-descriptions-item label="地址"
          >{{ dialogData.vehicleProvince }}{{ dialogData.vehicleCity
          }}{{ dialogData.vehicleCounty }}</el-descriptions-item
        >
        <el-descriptions-item label="进场时间">{{
          dialogData.slottingTime
        }}</el-descriptions-item>
        <el-descriptions-item label="品牌">{{
          dialogData.vehicleBrand
        }}</el-descriptions-item>
        <el-descriptions-item label="租赁方式">{{
          dialogData.leaseType
        }}</el-descriptions-item>
        <el-descriptions-item label="工作量"
          >{{ dialogData.leaseWork
          }}{{ dialogData.leaseUnit }}</el-descriptions-item
        >
        <el-descriptions-item label="租赁时间"
          >{{ dialogData.leaseTimeStart }}-{{
            dialogData.leaseTimeEnd
          }}</el-descriptions-item
        >
        <el-descriptions-item label="出厂时间">{{
          dialogData.vehicleProductionDate
        }}</el-descriptions-item>
        <el-descriptions-item label="所在定位">{{
          dialogData.vehicleLocation
        }}</el-descriptions-item>
        <el-descriptions-item label="车辆识别码">{{
          dialogData.vehicleIdentificationCode
        }}</el-descriptions-item>
        <el-descriptions-item label="发动机号码">{{
          dialogData.vehicleEngineNumber
        }}</el-descriptions-item>
      </el-descriptions>
      <el-descriptions
        style="margin-top: 20px"
        title="驾驶员信息"
        border
        :column="2"
        size="small"
      >
      </el-descriptions>
      <el-scrollbar>
        <div
          v-for="(v, i) in dialogData.cereOrderDrivers"
          :key="i"
          class="item"
        >
          <div>
            <el-descriptions
              style="margin-top: 20px"
              border
              :column="2"
              size="small"
            >
              <el-descriptions-item label="姓名">{{
                v.driverName
              }}</el-descriptions-item>
              <el-descriptions-item label="身份证">{{
                v.idCard
              }}</el-descriptions-item>
              <el-descriptions-item label="联系电话">{{
                v.phone
              }}</el-descriptions-item>
            </el-descriptions>
            <el-descriptions
              border
              direction="vertical"
              :column="2"
              size="small"
            >
              <el-descriptions-item label="驾驶证正面">
                <el-image
                  style="width: 100px; height: 100px"
                  :src="v.driverLicenseFront"
                ></el-image>
              </el-descriptions-item>
              <el-descriptions-item label="驾驶证反面">
                <el-image
                  style="width: 100px; height: 100px"
                  :src="v.driverLicenseBack"
                ></el-image>
              </el-descriptions-item>
            </el-descriptions>
          </div>
        </div>
      </el-scrollbar>

      <el-descriptions
        title="机械照片"
        direction="vertical"
        border
        size="small"
        style="margin-top: 20px"
      >
        <el-descriptions-item label="车辆照片">
          <el-image
            style="width: 100px; height: 100px; margin-right: 20px"
            :src="item"
            v-for="(item, index) in dialogData.vehicleImgs"
            :key="index"
          ></el-image>
        </el-descriptions-item>
      </el-descriptions>
      <el-descriptions border :column="1" direction="vertical" size="small">
        <el-descriptions-item label="机械铭牌/合格证">
          <el-image
            style="width: 100px; height: 100px"
            :src="dialogData.vehicleNameplateImg"
          ></el-image>
        </el-descriptions-item>
        <el-descriptions-item label="行驶证">
          <el-image
            style="width: 100px; height: 100px"
            :src="dialogData.dlImg"
          ></el-image>
        </el-descriptions-item>
      </el-descriptions>
      <el-descriptions border :column="2" direction="vertical" size="small">
        <el-descriptions-item label="车险保单">
          <el-image
            style="width: 100px; height: 100px"
            :src="dialogData.vehicleInsuranceImg"
          ></el-image>
        </el-descriptions-item>
      </el-descriptions>
    </el-dialog>
  </div>
</template>

<script>
import { getOrderById, orderOperation } from "@/api/order";
import { getUser } from "@/api/user";

export default {
  data() {
    return {
      dataJson: {},
      vehicleModifyVos: [],
      afterModifyPrice: null,
      afterModifyRemark: null, // 改价备注
      dialogVisible: false,
      dialogData: {},
      userInfo: {},
    };
  },
  created() {
    getOrderById(this.$route.query.id).then((res) => {
      this.dataJson = res.data;
      this.dataJson.amountTotal = res.data.amountTotal.toFixed(2);
      this.afterModifyRemark =
        res.data.orderModifyVo != null ? res.data.orderModifyVo.remark : "";
      this.afterModifyPrice =
        res.data.orderModifyVo != null
          ? res.data.orderModifyVo.afterModifyPrice.toFixed(2)
          : "";
      this.vehicleModifyVos =
        res.data.orderModifyVo != null
          ? res.data.orderModifyVo.vehicleModifyVos
          : [];
    });
    getUser().then((res) => {
      this.userInfo = res.data;
    });
  },
  methods: {
    checkDialog(v) {
      this.dialogVisible = true;
      this.dialogData = v;
    },
    submit(isToF, val) {
      this.$prompt(`请填写${val}`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(({ value }) => {
        let cereOrderParam = {
          buttonType: 4,
          orderId: this.$route.query.id,
          reason: value,
          acceptStatus: isToF,
        };
        orderOperation(cereOrderParam).then((res) => {
          this.$message.success("提交成功");
          this.$router.go(-1);
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-descriptions-item__container {
  display: flex;
  align-items: center;
}
.tile_box {
  border-bottom: 2px solid #dddddd;
  position: relative;
  margin-bottom: 10px;
  .title {
    position: absolute;
    top: -30px;
    font-size: 18px;
    font-weight: bold;
    border-bottom: 2px solid #fcb110;
    width: 72px;
    line-height: 30px;
  }
}
h3 {
  text-align: center;
}
::v-deep .el-descriptions :not(.is-bordered) .el-descriptions-item__cell {
  width: 193px;
}
::v-deep .el-scrollbar__wrap {
  overflow-x: hidden;
  max-height: 300px;
}
.el-scrollbar {
  padding-bottom: 10px;
  .items {
    margin-bottom: 10px;
  }
  .item {
    display: flex;
    margin-bottom: 10px;
    .flex {
      flex: 1;
      position: relative;
      .el-button {
        position: absolute;
        bottom: 2px;
        right: 0;
        background-color: #fcb110;
        border: none;
      }
    }
    .el-image {
      width: 150px;
      height: 127px;
    }
  }
}
</style>
