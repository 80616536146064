import request from '@/utils/request.js'
// 个人中心首页数据
export function getUserIndex() {
  return request({
    url: '/user/index',
    method: 'get'
  })
}

// 个人信息查询
export function getUser() {
  return request({
    url: '/user/getUser',
    method: 'get'
  })
}
// 修改个人信息
export function updateUser(data) {
  return request({
    url: '/user/update',
    method: 'post',
    data
  })
}

// 企业认证信息查询
export function getAuthInfo() {
  return request({
    url: '/company/getAuthInfo',
    method: 'get'
  })
}

// 提交企业认证信息
export function saveAuthInfo(data) {
  return request({
    url: '/company/saveAuthInfo',
    method: 'put',
    data
  })
}

// 车主认证信息查询
export function getById() {
  return request({
    url: '/check/getById',
    method: 'get'
  })
}

// 提交车主认证信息
export function personalCheck(data) {
  return request({
    url: '/check/personalCheck',
    method: 'post',
    data
  })
}

// 数据字典下拉查询
export function getSelect(v) {
  return request({
    url: '/app/getSelect?dictName=' + v,
    method: 'get'
  })
}

// 省市区树形查询
export function getCityTree() {
  return request({
    url: '/address/cityTree',
    method: 'get'
  })
}

// 选择城市查询（根据上级节点查询下级节点数据）
export function getCitySelect(data) {
  return request({
    url: '/address/getCitySelect',
    method: 'post',
    data
  })
}

// 机械类型树形查询
export function vehicleTypeTree() {
  return request({
    url: '/vehicleType/vehicleTypeTree',
    method: 'get'
  })
}

// 新增车辆
export function addVehicleInfo(data) {
  return request({
    url: '/vehicle/addVehicleInfo',
    method: 'post',
    data
  })
}

// 删除车辆
export function deleteCar(id) {
  return request({
    url: '/vehicle/' + id,
    method: 'delete'
  })
}

// 修改车辆
export function putVehicleInfo(data) {
  return request({
    url: '/vehicle',
    method: 'put',
    data
  })
}

// 查询车辆
export function getVehicleInfoByPage(data) {
  return request({
    url: '/vehicle/getVehicleInfoByPage',
    method: 'post',
    data
  })
}

// 查询品牌
export function getBrand() {
  return request({
    url: '/vehicleBrand',
    method: 'get'
  })
}

// 获取用户输入身份证联想身份证列表
export function getDriverIdCardsByIdCard() {
  return request({
    url: '/driver/getDriverIdCardsByIdCard',
    method: 'get'
  })
}

// 根据身份证获取驾驶员信息
export function getDriverByIdCard(data) {
  return request({
    url: '/driver/getDriverByIdCard ',
    method: 'post',
    data
  })
}

// 编辑查询信息
export function getVehicleInfoById(v) {
  return request({
    url: '/vehicle/getVehicleInfoById/' + v,
    method: 'get'
  })
}

// 查询需求列表
export function getRequireOrder(data) {
  return request({
    url: '/requireOrder/query',
    method: 'post',
    data
  })
}

// 创建/暂存
export function addRequireOrder(data) {
  return request({
    url: '/requireOrder/save',
    method: 'put',
    data
  })
}
// 提交审核
export function updateRequireOrder(data) {
  return request({
    url: '/requireOrder/update',
    method: 'put',
    data
  })
}

// 查询信息
export function getRequireOrderById(v) {
  return request({
    url: '/requireOrder/getById/' + v,
    method: 'get'
  })
}

// 删除需求
export function deleteNeed(v) {
  return request({
    url: '/requireOrder/delete?id=' + v,
    method: 'delete'
  })
}

// 发布需求
export function issueNeed(v) {
  return request({
    url: '/requireOrder/push?id=' + v,
    method: 'put'
  })
}

// 撤销需求
export function cancelNeed(v) {
  return request({
    url: '/requireOrder/cancel?id=' + v,
    method: 'put'
  })
}